import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  Content,
  ImageCollection,
  Reviews,
  CustomerReviewModal,
  Contact,
} from "@bluefin/components";
import { Grid, Image } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage, getPageGalleryImages } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={fishermanBusiness.name}
              tagline={"Columbia MO"}
              tagline={
                <div>
                  <h3 className={"tagline"}>Columbia MO</h3>
                </div>
              }
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[fishermanBusiness.ctas.phone]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
          subfooter={false}
        >
          <Grid
            stackable={true}
            className={"component-section-container rm-padding"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16} textAlign={"center"}>
              <Content>
                <Content.Markup>
                  {fishermanBusiness.aboutMarkdown}
                </Content.Markup>
              </Content>
              <p>
                <Image
                  size={"medium"}
                  centered={true}
                  src={
                    "https://fisherman.gumlet.io/public/cajuncrabhouseseafoodrestaurant/RestaurantGuru_Certificate1_preview_nocache.png"
                  }
                />
              </p>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container rm-padding"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={16}>
              <ImageCollection
                images={getPageGalleryImages({
                  options: fishermanBusinessWebsitePage.components,
                })}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={4}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container rm-padding"}
          >
            <Grid.Column width={16}>
              <Reviews
                reviews={fishermanBusiness.reviews}
                as={"horizontalItem"}
                buttonBasic={false}
                buttonSize={"medium"}
                centerHeader={true}
                cardInlinePhoto={"left"}
                header={"Reviews"}
                imageCircular={false}
                imageSize={"small"}
                itemButtonFloat={false}
                itemCenterContent={false}
                itemsDivided={false}
                itemsPerRow={3}
                linkAs={"none"}
                onlyEvenRows={true}
                showImage={true}
                verticalItemAlign={"left"}
              />
              <CustomerReviewModal
                businessId={fishermanBusiness._id}
                locations={allFishermanBusinessLocation.nodes}
                businessType={"restaurant"}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={"component-section-container rm-padding"}
          >
            <Grid.Column width={16}>
              <Contact
                businessName={fishermanBusiness.name}
                phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                email={fishermanBusiness.primaryLocation.email}
                hours={fishermanBusiness.primaryLocation.hours}
                address={{
                  street: fishermanBusiness.primaryLocation.street,
                  city: fishermanBusiness.primaryLocation.city,
                  state: fishermanBusiness.primaryLocation.state,
                  zip: fishermanBusiness.primaryLocation.zipCode,
                }}
                as={"text"}
                buttonColor={"secondary"}
                header={"Contact"}
                image={""}
                showMap={true}
              />
              <div className={"ui container"}>
                <div className={"ui stackable grid"}>
                  <div className={"eleven wide column contact-graphic"} />
                </div>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
      aboutMarkdown
      reviews {
        author
        text
        link
      }
      _id
      primaryLocation {
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Home" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
